import React from "react"
import { FaYoutube, FaInstagram, FaTwitter } from "react-icons/fa"
import styled from "styled-components"

const SocialLinks = ({ styleClass }) => {
  return (
    <Wrapper>
      <ul className={styleClass}>
        <li>
          <a
            href="https://www.youtube.com/channel/UCGO_ARW6XNzvsJ20-R1ssrg"
            aria-label="youtube"
          >
            <FaYoutube className="social-icon youtube-icon"></FaYoutube>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/onemuneko/" aria-label="instagram">
            <FaInstagram className="social-icon instagram-icon"></FaInstagram>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/onemuneco" aria-label="twitter">
            <FaTwitter className="social-icon twitter-icon"></FaTwitter>
          </a>
        </li>
      </ul>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .youtube-icon {
    color: #c4302b;
  }
  .twitter-icon {
    color: #00acee;
  }
  .instagram-icon {
    color: #ea4c89;
  }
`
export default SocialLinks
