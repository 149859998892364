import React from "react"
import { Link } from "gatsby"

const Links = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/" className="page-link">
          ホーム
        </Link>
      </li>
      <li>
        <Link to="/works" className="page-link">
          作品
        </Link>
      </li>
      <li>
        <Link to="/posts" className="page-link">
          投稿
        </Link>
        {children}
      </li>
    </ul>
  )
}

export default Links
