import React from "react"
import styled from "styled-components"
import SocialLinks from "../constants/socialLinks"

const Footer = () => {
  return (
    <Wrapper>
      <div>
        <SocialLinks styleClass="footer-icons" />
        <p>
          &copy;{new Date().getFullYear()} おねむねこのねどこ ALL RIGHTS
          RESERVED.
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  height: 7rem;
  text-align: center;
  background: var(--clr-black);
  display: grid;
  place-items: center;
  p {
    margin-bottom: 0;
    color: var(--clr-white);
    text-transform: uppercase;
    letter-spacing: var(--spacing);
  }
  .footer-icons {
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 0.5rem;
    a {
      font-size: 1.5rem;
      margin: 0 0.5rem;
      transition: var(--transition);
    }
    li {
      transition: var(--transition);
    }
    li:hover {
      transform: translateY(-10%);
    }
  }
`

export default Footer
