import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Title from "./Title"

const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "works" } }) {
      nodes {
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const Works = ({ works, title }) => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(query)

  return (
    <Wrapper>
      <Title title={title} />
      <div className="section-center">
        {works.map(work => {
          const image = images.find(image => image.name === work.image)
          const { id, title, vocal } = work
          return (
            <article key={id}>
              <div className="container">
                <Link
                  to="https://www.youtube.com/watch?v=Cnn18oDCBwE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image fluid={image.childImageSharp.fluid} className="img" />
                  <div className="info">
                    <p>- {vocal} -</p>
                    <h3>{title}</h3>
                  </div>
                </Link>
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 4rem;
  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    .img {
      height: 20rem;
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .container {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-7);
      &:hover .img {
        opacity: 0.2;
      }
      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        color: var(--clr-white);
        text-align: center;
        p {
          margin-bottom: 0.5rem;
          color: var(--clr-white);
          text-transform: uppercase;
        }
      }
      &:hover .info {
        opacity: 1;
      }
    }
    @media (min-width: 768px) {
      .img {
        height: 15rem;
      }
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      .img {
        height: 12.5rem;
      }
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
      .img {
        height: 15rem;
      }
    }
  }
`

export default Works
