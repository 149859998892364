import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import Title from "./Title"

const Posts = ({ posts, title }) => {
  return (
    <Wrapper>
      <Title title={title} />
      <div className="section-center posts-center">
        {posts.map(post => {
          const { id, frontmatter, excerpt } = post
          const { title, image, slug, date, category } = frontmatter
          return (
            <Link to={`/posts/${slug}`} className="post" key={id}>
              <article>
                <Image
                  fluid={image.childImageSharp.fluid}
                  className="post-img"
                />
                <div className="post-card">
                  <h4>{title}</h4>
                  <p>{excerpt}</p>
                  <div className="post-footer">
                    <p>{category}</p>
                    <p>{date}</p>
                  </div>
                </div>
              </article>
            </Link>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 2rem;
  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
  }
  .post {
    display: block;
    margin-bottom: 2rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    transition: var(--transition);
  }
  .post:hover {
    box-shadow: var(--dark-shadow);
    transform: scale(1.02);
  }
  .post article {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .post-card {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  .post-img {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    height: 15rem;
  }
  .post-card {
    padding: 1.5rem 1rem;
  }
  .post-card h4 {
    color: var(--clr-grey-1);
  }

  .post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .post-footer p {
    margin-bottom: 0;
    font-weight: bold;
    color: var(--clr-grey-5);
  }
  .post-footer p:first-of-type {
    display: inline-block;
    background: var(--clr-grey-9);
    color: var(--clr-grey-5);
    margin-right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.85rem;
  }
  @media screen and (min-width: 576px) {
    .posts-center {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
      column-gap: 2rem;
    }
    .post-img {
      height: 17.5rem;
    }
  }
  @media screen and (min-width: 850px) {
    .post-img {
      height: 13.75rem;
    }
  }
`

export default Posts
