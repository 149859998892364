import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

const query = graphql`
  {
    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const { hero } = useStaticQuery(query)
  return (
    <Wrapper>
      <Image
        fluid={hero.childImageSharp.fluid}
        className="hero-img"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
      <div
        className="hero-cover"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className="hero-content"
          style={{
            height: "80%",
            margin: "auto 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <h1 className="hero-h1">おねむねこのねどこ</h1>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  height: 100vh;
  .hero-img {
    left: 0;
    top: 0;
    z-index: -2;
  }
  .hero-cover {
    left: 0;
    top: 0;
    background-color: hsla(210, 2%, 58%, 0.4);
    z-index: -1;
  }
  .hero-h1 {
    color: var(--clr-primary-10);
    font-size: 2.5rem;

    @media screen and (min-width: 800px) {
      font-size: 8rem;
    }
  }
`

export default Hero
